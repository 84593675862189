<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ providerType === 'deposit' ? $t("payment_methods.deposit_providers") : $t("payment_methods.withdraw_providers") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <router-link
          :to="`/payment-methods-management/new/${providerType}`"
          class="btn btn-primary font-weight-bolder"
          v-if="checkPermission('crm.providers.payments.create')"
        >
          <span class="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" cx="9" cy="15" r="6" />
                <path
                  d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
            <!--end::Svg Icon--> </span
          >
          {{ providerType === 'deposit' ? $t("payment_methods.add_deposit_new") : $t("payment_methods.add_withdraw_new") }}
        </router-link>
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <div v-if="isLoading">
        <div class="text-center">{{ $t("commons.data_loading") }}...</div>
      </div>
      <table
        class="table table-striped table-bordered collapsed"
        style="width: 100%"
        v-else
        :ref="`kt_datatable_${providerType}`"
      >
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t("payment_methods.unit_type") }}</th>
            <th scope="col">{{ $t("payment_methods.bank") }}</th>
            <th scope="col">{{ $t("payment_methods.unit_name") }}</th>
            <th scope="col">{{ $t("payment_methods.currency") }}</th>
            <th scope="col">{{ $t("payment_methods.min_unit") }}</th>
            <th scope="col">{{ $t("payment_methods.max_unit") }}</th>
            <th scope="col">{{ $t("payment_methods.fee") }}</th>
            <th scope="col">{{ $t("payment_methods.status") }}</th>
            <th scope="col">{{ $t("commons.operations") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in providers" :key="`${providerType}-providersList-${index}`">
            <th scope="row">{{ item.id }}</th>
            <td>{{ $t(`payment_methods.service_provider_types.${item.provider}`) }}</td>
            <td>{{ item.bank }}</td>
            <td>{{ item.name || '' }}</td>
            <td>{{ renderCurrency(item.currency) }}</td>
            <td>{{ item.min_pay || '' }}</td>
            <td>{{ item.max_pay || '' }}</td>
            <td>{{ renderFee(item) }}</td>
            <td>
                <span class="badge" :class="[+item.status === 10 ? 'badge-success' : 'badge-warning']">
                    {{ $t(`payment_methods.service_status.${item.status}`) }}
                </span>
            </td>
            <td>
              <router-link
                v-if="checkPermission('crm.providers.payments.edit')"
                :to="`/payment-methods-management/update/${item.id}`"
                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                :title="$t('commons.edit')"
              >
                <i class="la la-pencil"></i>
              </router-link>
              <button
                v-if="checkPermission('crm.providers.payments.destroy')"
                type="button"
                @click="deleteProvider(item.id)"
                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                :title="$t('commons.delete')"
              >
                <i class="la la-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "list",
  props: {
      providerType: {
        tpye: String,
        default: 'deposit'
      }
  },
  data() {
    return {};
  },
  components: { },
  computed: {
    ...mapState({
      isLoading: (state) => state.paymentMethodsManagemet.isLoading,
      depositProviders: (state) => state.paymentMethodsManagemet.depositProviders,
      withdrawProviders: (state) => state.paymentMethodsManagemet.withdrawProviders,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    providers() {
        if(this.providerType === 'deposit') return this.depositProviders;
        return this.withdrawProviders;
    }
  },
  methods: {
    ...mapActions("paymentMethodsManagemet", ["GET_PROVIDERS", "DESTROY"]),
    checkPermission(permission) {
      return this.permissions?.some(
        (item) => String(item.name) === String(permission)
      );
    },
    renderCurrency(currency) {
        if(currency && Object.keys(currency).length > 0) return `${currency.name} (${currency.code})`
        return '';
    },
    renderFee(item) {
        if(item.fee_decimal && item.fee_type) return `${item.fee_decimal} (${item.fee_type})`
        return item.fee_decimal
    },
    deleteProvider(id) {
        const vm = this;
        Swal.fire({
          title: this.$t("commons.are_you_sure"),
          text: this.$t("commons.wont_be_able_to_revert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("commons.yes_delete_it"),
          cancelButtonText: this.$t("commons.cancel"),
        }).then(function (result) {
          if (result.value) {
            vm.DESTROY(id)
          }
        });
    },
    intializeDtTable() {
      const self = this;
      self.GET_PROVIDERS().then(() => {
        self.$nextTick(function () {
          $(self.$refs[`kt_datatable_${self.providerType}`]).DataTable({
            responsive: true,
            pagingType: "full_numbers",
            dom: "lfrtip",
            pageLength: 10,
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"],
            ],
            columnDefs: [
              { orderable: false, targets: [-1, -2] },
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`,
            },
          });
        });
      });
    },
  },
  created() {
      this.intializeDtTable();
  },
};
</script>
